<script lang="ts" setup>
import type { Galleries } from '~/types/MainPage';

defineProps<{ data: Galleries }>();

const id = useId();

function scrollLeft() {
  const slider = document.getElementById(id);
  slider?.scrollBy({ left: -400, behavior: 'smooth' });
}

function scrollRight() {
  const slider = document.getElementById(id);
  slider?.scrollBy({ left: 400, behavior: 'smooth' });
}
</script>

<template>
  <section class="unified-gallery">
    <div
      v-if="data.title"
      class="px-3 lg:px-0"
    >
      <AppLink
        v-if="data.url"
        :to="data.url"
        class="w-fit"
      >
        <h2 class="inline underline underline-offset-4">
          {{ data.title }}
        </h2>
      </AppLink>

      <h2
        v-else
        class="inline"
      >
        {{ data.title }}
      </h2>
    </div>

    <div class="unified-gallery__slider">
      <div
        :id
        class="unified-gallery__slider-wrapper"
      >
        <AppLink
          v-for="image in data.gallery_mobile"
          :key="image.full"
          :class="{ 'hover:opacity-80': image.url }"
          :to="makeUrlRelative(image.url)"
          class="unified-gallery__image !w-fit !block md:!hidden transition-opacity"
        >
          <picture class="flex place-content-center size-full">
            <source
              :srcset="image.thumb_webp.original"
              type="image/webp"
            />

            <img
              :src="image.full"
              alt=""
              class="object-contain"
            />
          </picture>

          <div class="unified-gallery__text-over-image">
            <div
              v-if="image.text_over_image_small"
              class="font-bold"
            >
              {{ image.text_over_image_small }}
            </div>

            <div
              v-if="image.text_over_image_big"
              class="font-bold text-xl !leading-none"
            >
              {{ image.text_over_image_big }}
            </div>
          </div>

          <div
            v-if="image.text_over_image_small || image.text_over_image_big"
            class="unified-gallery__text-over-image-gradient"
          />
        </AppLink>

        <AppLink
          v-for="image in data.gallery_desktop"
          :key="image.full"
          :class="{ 'hover:opacity-80': image.url }"
          :to="makeUrlRelative(image.url)"
          class="unified-gallery__image !w-fit !hidden md:!block transition-opacity"
        >
          <picture class="flex place-content-center size-full">
            <source
              :srcset="image.thumb_webp.original"
              type="image/webp"
            />

            <img
              :src="image.full"
              alt=""
              class="object-contain"
            />
          </picture>

          <div class="unified-gallery__text-over-image">
            <div
              v-if="image.text_over_image_small"
              class="font-bold"
            >
              {{ image.text_over_image_small }}
            </div>

            <div
              v-if="image.text_over_image_big"
              class="font-bold text-xl !leading-none"
            >
              {{ image.text_over_image_big }}
            </div>
          </div>

          <div
            v-if="image.text_over_image_small || image.text_over_image_big"
            class="unified-gallery__text-over-image-gradient"
          />
        </AppLink>
      </div>

      <section
        v-if="data.gallery_desktop.length > 1"
        class="swiper-navigation"
      >
        <button
          class="swiper-button swiper-button--prev"
          @click="scrollLeft"
        >
          <SvgoChevronLeft
            class="text-2xl"
            filled
          />
        </button>

        <button
          class="swiper-button swiper-button--next"
          @click="scrollRight"
        >
          <SvgoChevronRight
            class="text-2xl"
            filled
          />
        </button>
      </section>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.swiper-navigation {
  top: calc(50% - 20px);
}

.unified-gallery {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include screen-lg {
    @include inner-content;
  }

  &__slider {
    position: relative;
    display: flex;
    overflow: hidden;

    &:hover {
      .swiper-navigation {
        opacity: 1;
      }
    }
  }

  &__slider-wrapper {
    display: flex;
    gap: 12px;
    overflow: auto;
    touch-action: pan-x pan-y;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__image {
    position: relative;
    flex-shrink: 0;
    object-fit: cover;
    overflow: hidden;
    width: fit-content;
    border-radius: 12px;

    &:first-of-type {
      margin-left: 12px;
    }

    &:last-of-type {
      margin-right: 12px;
    }

    @include screen-lg {
      &:first-of-type {
        margin: 0;
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }

  &__text-over-image {
    position: absolute;
    bottom: 8px;
    left: 10px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    gap: 6px;
    color: $white;
  }

  &__text-over-image-gradient {
    position: absolute;
    bottom: 0;
    z-index: 4;
    width: 100%;
    height: 40%;
    background: linear-gradient(to top, rgb(0 0 0 / 100%), rgb(0 0 0 / 0%));
  }
}
</style>
