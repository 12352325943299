<script lang="ts" setup>
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper } from 'swiper';
import type { Ref } from 'vue';
import type { Galleries, GalleryDesktopImage, GalleryMobileImage } from '~/types/MainPage';
import type { Swiper as SwiperType } from 'swiper/types';

const props = defineProps<{ data: Galleries }>();

const mainBannerSwiper: Ref<SwiperType | null> = ref(null);
const mainBannerSwiperRef = ref();
const swiperPaginationBlock = ref();

function sliderInitialization() {
  mainBannerSwiper.value = new Swiper(mainBannerSwiperRef.value, {
    modules: [Autoplay, Navigation, Pagination],
    navigation: {
      prevEl: '.swiper-button--prev',
      nextEl: '.swiper-button--next',
    },
    pagination: {
      clickable: true,
      el: swiperPaginationBlock.value,
      type: 'bullets',
    },
    rewind: true,
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 1000,
  });
}

const { sendBannerEventToDataLayer } = useGtmModule();
const { isMobile } = useBreakpoints();
const activeBanner = ref<GalleryDesktopImage | GalleryMobileImage | null>(null);

onMounted(() => {
  sliderInitialization();

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          mainBannerSwiper.value?.autoplay?.start();
          mainBannerSwiper.value?.on('slideChange', () => {
            if (mainBannerSwiper.value?.realIndex) {
              activeBanner.value = isMobile
                ? props.data.gallery_mobile[mainBannerSwiper.value.realIndex]
                : props.data.gallery_desktop[mainBannerSwiper.value.realIndex];

              sendBannerEventToDataLayer('view_promotion', {
                promotion_id: activeBanner.value?.url,
                promotion_name: activeBanner.value?.full,
                creative_slot: mainBannerSwiper.value.realIndex,
                location_id: 'home_slider',
              });
            }
          });
        } else {
          mainBannerSwiper.value?.autoplay.pause();
          mainBannerSwiper.value?.off('slideChange');
        }
      });
    },
    { threshold: 0.7 },
  );

  observer.observe(mainBannerSwiperRef.value);
});

function sendSelectPromotionEvent(image: GalleryDesktopImage | GalleryMobileImage) {
  sendBannerEventToDataLayer('select_promotion', {
    promotion_id: image.url,
    promotion_name: image.full,
    creative_slot: mainBannerSwiper.value?.realIndex,
    location_id: 'home_slider',
  });
}
</script>

<template>
  <section class="main-banner-gallery">
    <section
      ref="mainBannerSwiperRef"
      class="swiper w-full"
    >
      <div class="swiper-wrapper">
        <AppLink
          v-for="image in data.gallery_mobile"
          :key="image.full"
          :to="makeUrlRelative(image.url)"
          class="swiper-slide main-banner-gallery__image !block md:!hidden"
          @click="sendSelectPromotionEvent(image)"
        >
          <picture class="flex place-content-center size-full">
            <source
              v-if="image.thumb_webp.thumb_350"
              :srcset="
                image.thumb_webp.thumb_700
                  ? `${image.thumb_webp.thumb_350}, ${image.thumb_webp.thumb_700} 2x`
                  : image.thumb_webp.thumb_350
              "
              type="image/webp"
            />
            <source
              v-if="image.thumb.thumb_350"
              :srcset="image.thumb.thumb_350"
              type="image/jpeg"
            />
            <img
              :alt="image.alt"
              :src="image.full"
              class="object-contain rounded-2xl overflow-hidden"
            />
          </picture>
        </AppLink>

        <AppLink
          v-for="image in data.gallery_desktop"
          :key="image.full"
          :to="makeUrlRelative(image.url)"
          class="swiper-slide main-banner-gallery__image !hidden md:!block"
          @click="sendSelectPromotionEvent(image)"
        >
          <picture class="flex place-content-center size-full">
            <source
              v-if="image.thumb_webp.thumb_1400"
              :srcset="
                image.thumb_webp.thumb_2800
                  ? `${image.thumb_webp.thumb_1400}, ${image.thumb_webp.thumb_2800} 2x`
                  : image.thumb_webp.thumb_1400
              "
              class="object-contain"
              type="image/webp"
            />
            <source
              v-if="image.thumb.thumb_1400"
              :srcset="image.thumb.thumb_1400"
              class="object-contain"
              type="image/jpeg"
            />
            <img
              :src="image.full"
              alt=""
              class="object-contain rounded-2xl overflow-hidden"
            />
          </picture>
        </AppLink>
      </div>

      <section
        v-if="data.gallery_desktop.length > 1"
        class="swiper-navigation"
      >
        <div class="swiper-button swiper-button--prev">
          <SvgoChevronLeft
            class="text-2xl"
            filled
          />
        </div>

        <div class="swiper-button swiper-button--next">
          <SvgoChevronRight
            class="text-2xl"
            filled
          />
        </div>
      </section>

      <section
        ref="swiperPaginationBlock"
        class="swiper-pagination"
      />
    </section>
  </section>
</template>

<style lang="scss">
.main-banner-gallery {
  @include inner-content;

  &__image {
    position: relative;
    overflow: hidden;
    width: 100% !important;
  }
}
</style>
